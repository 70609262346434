import { useEffect, useState } from "react";
import "./App.css";
import Timer from "./components/Timer";
import Yzi from "./components/Yzi";

import ReactGA from "react-ga4";
ReactGA.initialize("G-CLCQJV3BSW");

function App() {
  return <Yzi />;
}

export default App;

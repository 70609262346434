import React, { useEffect, useRef, useState } from "react";
import { Canvas, useThree, useFrame, extend } from "@react-three/fiber";
import { OrbitControls, Line } from "@react-three/drei";
import { FontLoader } from "three/examples/jsm/loaders/FontLoader";
import { TextGeometry } from "three/examples/jsm/geometries/TextGeometry";
import * as THREE from "three";
import myFont from "./AktivGrotesk-Black.otf";
import { DoubleSide } from "three";

import ReactGA from "react-ga4";

extend({ TextGeometry });

const getRandom = (min, max) => {
  return Math.random() * (max - min) + min;
};

const Box = (props) => {
  const boxRef = useRef();

  useFrame(() => {
    boxRef.current.rotation.z += 0.01;
    boxRef.current.rotation.x += 0.01;
  });

  const font = new FontLoader().parse(myFont);

  return (
    <mesh
      ref={boxRef}
      rotation-x={Math.PI * 0.25}
      rotation-y={Math.PI * 0.25}
      onClick={props.onClick}
    >
      <capsuleGeometry args={[2, 5, 4, 20]} />
      <meshStandardMaterial color={"white"} />
    </mesh>
  );
};

const Yzi = () => {
  const [bgColor, setBgColor] = useState("#fff"); // Set initial background color to black
  const [colorIndex, setColorIndex] = useState(0);
  const colors = ["black", "red", "blue", "magenta", "cornflowerblue"];

  // useEffect(() => {
  //   document.body.style.backgroundColor = bgColor;
  // }, [bgColor]);

  useEffect(() => {
    document.body.style.backgroundColor = colors[colorIndex];
  }, [colorIndex]);

  const changeBackgroundColor = () => {
    ReactGA.event({
      category: "Button",
      action: "Click",
      label: "bg changed",
    });
    setColorIndex((colorIndex + 1) % colors.length);
  };

  // const changeBackgroundColor = () => {

  //   // setBgColor(`#${Math.floor(Math.random() * 16777215).toString(16)}`); // Change to a random color
  //   const randomColor = colors[Math.floor(Math.random() * colors.length)];
  //   setBgColor(randomColor);
  // };

  return (
    <Canvas camera={{ fov: 120, position: [-15, -15, -15] }}>
      <ambientLight intensity={0.3} />
      <spotLight
        position={[10, 10, 10]}
        angle={0.5}
        penumbra={1}
        intensity={0.6}
      />
      <pointLight position={[-10, -10, -10]} />
      <OrbitControls />
      {[...Array(1000)].map((x, i) => (
        <Line
          points={[
            [
              getRandom(-1000, 1000),
              getRandom(-1000, 1000),
              getRandom(-1000, 1000),
            ],
            [
              getRandom(-1000, 1000),
              getRandom(-1000, 1000),
              getRandom(-1000, 1000),
            ],
          ]}
          color="white"
          position={[0, 0, 0]}
          linewidth={1}
        />
      ))}

      <Box onClick={changeBackgroundColor} />
    </Canvas>
  );
};

export default Yzi;
